import { useGlobalNavStyles } from '@shared/components/GlobalNav/GlobalNav.styles';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { NavLink } from '@soil-capital/ui-kit';
import { useGlobalNav } from '@shared/components/GlobalNav/useGlobalNav';
import { SelectSeasons } from '@modules/encoding/modules/layout/components/SelectSeasons/SelectSeasons';

export const GlobalNav = () => {
    const { isLoading, links, isPartnerPrepaying } = useGlobalNav();
    const { classes } = useGlobalNavStyles();

    if (isLoading)
        return (
            <nav className={classes.nav} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Skeleton variant="text" width={170} height={50} />
                <Skeleton variant="rounded" width={235} height={60} />
                <Skeleton variant="rounded" width={235} height={60} />
            </nav>
        );

    return (
        <nav className={classes.nav} data-testid="global-nav">
            <SelectSeasons className={classes.select} />
            <ul className={isPartnerPrepaying ? classes.links : `${classes.links} ${classes.bottomLink}`}>
                {links.map((link) => (
                    <NavLink key={link.text} {...link} />
                ))}
            </ul>
        </nav>
    );
};
