import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useLocation, useNavigate } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useTranslation } from 'react-i18next';
import { IconFarm, IconRye, IconGraph } from '@soil-capital/ui-kit/icons';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { NavLinkProps } from '@soil-capital/ui-kit';
import { useIsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { IconCreditCard } from '@soil-capital/ui-kit/icons';
import { useIsPartnerPrepayingGuard } from '@shared/guards/IsPartnerPrepayingGuard';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

export const useGlobalNav = () => {
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason } = useCurrentSeason();
    const location = useLocation();
    const { t } = useTranslation();
    const { isHistoryFarmSeason, isLoading: isLoadingHistoryFarmSeason } = useBaselineFarmSeason();
    const isEncodingDone = useIsEncodingDoneGuard();
    const isPartnerPrepaying = useIsPartnerPrepayingGuard();
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const { hasAccessToResults, isLoading: isLoadingResultsPermissions, canSeeResults } = useCanSeeResults();
    const hasResults = currentSeason?.has_results;

    const isLoading =
        isLoadingHarvestYear ||
        isLoadingResultsPermissions ||
        isPartnerPrepaying === undefined ||
        isLoadingHistoryFarmSeason;

    const handleClick = (path: string) => navigate(path);

    const encodingNextStepPath =
        nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
            ? `/${currentSeasonId}/${getRouteMatchingStep(nextStepToComplete?.slug)}`
            : `/${currentSeasonId}/preparation`;
    const links: NavLinkProps[] = [
        {
            icon: <IconFarm />,
            text: t('layout.global-nav.home'),
            active: new RegExp(`^/${currentSeasonId}/?$`).test(location.pathname),
            onClick: () => handleClick(`/${currentSeasonId}/`),
        },
        {
            icon: <IconRye />,
            text: t('layout.global-nav.encoding'),
            active: false,
            onClick: () => handleClick(encodingNextStepPath),
        },
        ...(isEncodingDone && hasResults && !isHistoryFarmSeason
            ? [
                  {
                      icon: <IconGraph />,
                      text: t('layout.global-nav.results'),
                      active: new RegExp(`^/${currentSeasonId}/results/${resultRoutesConfig.SUMMARY}/?$`).test(
                          location.pathname,
                      ),
                      onClick: () => handleClick(`/${currentSeasonId}/results/${resultRoutesConfig.SUMMARY}`),
                      subLinks:
                          canSeeResults && new RegExp(`^/${currentSeasonId}/results/*`).test(location.pathname)
                              ? [
                                    {
                                        label: t('layout.global-nav.results.earning'),
                                        active: new RegExp(
                                            `^/${currentSeasonId}/results/${resultRoutesConfig.EARNING}/?$`,
                                        ).test(location.pathname),
                                        onClick: () =>
                                            handleClick(`/${currentSeasonId}/results/${resultRoutesConfig.EARNING}`),
                                        disabled: !hasAccessToResults,
                                        subtext: !hasAccessToResults ? t('constants.soon-available') : undefined,
                                    },
                                ]
                              : undefined,
                  },
              ]
            : []),
        ...(!isPartnerPrepaying
            ? [
                  {
                      id: 'billing',
                      style: { alignSelf: 'flex-end', marginBottom: 'auto' },
                      icon: <IconCreditCard />,
                      text: t('layout.global-nav.billing'),
                      active: location.pathname === `/${currentSeasonId}/billing`,
                      onClick: () => handleClick(`/${currentSeasonId}/billing`),
                  },
              ]
            : []),
    ];

    return { harvestYear, isLoading, links, isPartnerPrepaying, isHistoryFarmSeason };
};
